import {Component} from '@angular/core';
import {AdminService} from './admin.service';
import {PanelWebinarService} from './panels/panel-webinar/panel-webinar.service';
import {AdminDialog} from './admin.dialog';

@Component({
  selector: 'admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent {

  /**
   * Groups object
   */
  Groups;

  constructor(
    readonly service: AdminService,
    readonly panelWebinarService: PanelWebinarService,
    readonly adminDialog: AdminDialog,
  ) {
    this.service.validateLogin();

    /**
     * awaiting for the response of the fetch
     */
    service.getWebinarGroups().then(result => {
      /**
       * subscribing to the observable
       */
      result.subscribe(data => {
        /**
         * Sorting the data
         */
        const SortedGroups = service.sortWebinarGroups(data);
        /**
         * Sorting the Webinars
         */
        SortedGroups.forEach(Group => {
          Group.webinars = panelWebinarService.sortWebinars(Group.webinars);
        });

        this.Groups = SortedGroups;
      });
    });
  }

}
