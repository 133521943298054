import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface ShareWebinarHandoutData {
  file: any;
  amount: number;
}

@Component({
  selector: 'admin-dialog-webinar-handout-share',
  templateUrl: './webinar-share-handout-dialog.component.html',
  styleUrls: ['./webinar-share-handout-dialog.component.scss'],
})
export class DialogWebinarHandoutShare {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ShareWebinarHandoutData,
  ) {}

  onChange($event: Event) {
    this.data.file = $event.target['files'][0];
  }
}
