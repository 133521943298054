<h1 mat-dialog-title>
  Code versenden (Öffentlich)
</h1>

<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput [(ngModel)]="data.fullName" [formControl]="controls.fullNameControl">
    <mat-error *ngIf="controls.fullNameControl.invalid">{{ getErrorMessage(controls.fullNameControl) }}</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>E-Mail</mat-label>
    <input matInput [(ngModel)]="data.mail" [formControl]="controls.mailControl">
    <mat-error *ngIf="controls.mailControl.invalid">{{ getErrorMessage(controls.mailControl) }}</mat-error>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button
    mat-flat-button 
    color="primary" 
    [mat-dialog-close]="data" 
    cdkFocusInitial
    [disabled]="!isFormValid()"
  >
    Code versenden
  </button>
</div>
