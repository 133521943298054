import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormControl, Validators} from '@angular/forms';

export interface CreateWebinarGroupData {
  groupName: string,
  /**
   * image is optional
   */
  image?: string
}

@Component({
  selector: 'admin-dialog-webinar-group-create',
  templateUrl: './create-webinar-group-dialog.component.html',
  styleUrls: ['./create-webinar-group-dialog.component.scss'],
})
export class DialogWebinarGroupCreate {

  /**
   * Form Controls
   */
  controls = {
    groupNameControl: new FormControl('', [Validators.required]),
    imageControl: new FormControl('', [])
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CreateWebinarGroupData,
  ) {}

  
  /**
   * Get the fitting error message
   */
  getErrorMessage(control: FormControl): string | void {
    if (control.hasError('required')) {
      return 'Bitte fülle das Feld aus';
    }
  }

  /**
   * used to disable the button until the form is valid
   */
  isFormValid(): boolean {
    let valid = true;

    if (this.controls.groupNameControl.hasError('required')) {
      valid = false;
    }

    return valid;
  }

}
