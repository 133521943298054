import {Component, Input} from '@angular/core';
import {PaymentService} from '../../../service/payment.service';

interface CardTitleObject {
  /**
   * Big Title
   */
  headline: string;
  /**
   * Small Title under the headline
   */
  subheadline: string;
}

export interface CardDisaledObject {
  /**
   * label that will be displayed as the button label
   * if the card is disabled
   */
  label: string;
}



@Component({
  selector: 'payment-checkout-card',
  templateUrl: './checkout-card.component.html',
  styleUrls: ['./checkout-card.component.scss']
})
export class CheckoutCardComponent {

  /**
   * Card will be deactivated if a CardDisabledObject
   * is provided as disabled argument
   * (Optional)
   */
  @Input() disabled?: CardDisaledObject;

  /**
   * Usually used to showcase the topseller
   * (Optional)
   */
  @Input() banner?: string;

  /**
   * Display the Headline and the Subheadline
   */
  @Input() title: CardTitleObject;

  /**
   * Price that will be displayed on the card
   */
  @Input() price: string | number;

  /**
   * The button Label
   */
  @Input() label: string;

  /**
   * This is used to detect the
   * selected card in the back-end
   *
   * Every Ticket has a index (1-6)
   * 1-3 are Tickets for Customers
   * 4-6 are Tickets for Business
   */
  @Input() stripeIndex: number;



  constructor(
    private readonly paymentService: PaymentService
  ) {}



  /**
   * Handles the Payment with Stripe
   * @param $event - Click Event
   */
  async onClick($event: any): Promise<void> {
    /**
     * Prevent this Method from being executed
     * when then button is disabled
     */
    if (!!this.disabled) {
      return;
    }

    /**
     * disabling the default behaviours
     * of a button of a a-tag
     *
     * Example: Being redirected to a url on click on a a-tag
     */
    $event.preventDefault();

    /**
     * converting the stripeIndex into a number
     * using the + operator in front of it
     */
    const index: number = +this.stripeIndex;

    if (index > 3) {
      /**
       * Open the Dialog for the Company Name
       * because the index is bigger than 3
       * and index: 4-6 are business cards where
       * the company name is required to create it
       * ___
       * The company dialog will return the entered company name
       */
      const companyName: string = await this.paymentService.openPaymentCompanyNameDialog();

      /**
       * Create a checkout after the companyName was resolved
       */
      await this.paymentService.createCheckout(index, companyName);
    } else {
      /**
       * Create a checkout with the index
       */
      await this.paymentService.createCheckout(index);
    }
  }

}
