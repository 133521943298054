import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'admin-dialog-utils-share-success',
  templateUrl: './webinar-share-success-dialog.component.html',
  styleUrls: ['./webinar-share-success-dialog.component.scss'],
})
export class DialogUtilsWebinarSuccessDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogUtilsWebinarSuccessDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  close(): void {
    this.dialogRef.close();
  }
}
