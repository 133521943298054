<h1 mat-dialog-title>
  Statistik - (Webinar Gruppe | {{data.Group.name}})
</h1>

<div mat-dialog-content>
  <!-- Expansion Panel -->
  <mat-expansion-panel 
    class="dialog__table"
    expanded 
  >
    <!-- Header -->
    <mat-expansion-panel-header>
      <p>
        Codes <strong>({{data.codes.length}})</strong>
      </p>
    </mat-expansion-panel-header>

    <!-- Content -->
    <ng-template matExpansionPanelContent>
      <!-- Header -->
      <div class="dialog__table__header">
        <p>
          <strong>
            Code
          </strong>
        </p>

        <p>
          <strong>
            Name
          </strong>
        </p>

        <p>
          <strong>
            E-Mail
          </strong>
        </p>

        <p>
          <strong>
            Status
          </strong>
        </p>
      </div>

      <!-- Content -->
      <div class="dialog__table__column" *ngFor="let code of data.codes">
        <p>
          {{code.code}}
        </p>
        <p>
          {{code.name}}
        </p>
        <p>
          {{code.mail}}
        </p>
        <p 
          [class.dialog__table__column--used]="code.status=='eingelößt'" 
          [class.dialog__table__column--unused]="code.status=='ungenutzt'"
        >
          {{code.status}}
        </p>
      </div>

    </ng-template>
  </mat-expansion-panel>
</div>


<!--
  ACTION BUTTONS
-->
<div mat-dialog-actions>
  <button 
    class="dialog__button"
    mat-stroked-button 
    cdkFocusInitial
    (click)="close()" 
  >
    Schließen
  </button>
</div>
