<h1 mat-dialog-title>
  Content bearbeiten
</h1>

<div mat-dialog-content>
  <div class="field">
    <mat-form-field>
      <mat-label>Hero Title</mat-label>
      <input matInput [(ngModel)]="data.heroTitle" [formControl]="controls.heroTitleControl">
      <mat-error *ngIf="controls.heroTitleControl.invalid">{{ getErrorMessage(controls.heroTitleControl) }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Hero Text</mat-label>
      <textarea matInput [(ngModel)]="data.heroText" [formControl]="controls.heroTextControl"></textarea>
      <mat-error *ngIf="controls.heroTextControl.invalid">{{ getErrorMessage(controls.heroTextControl) }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Hero Button Text</mat-label>
      <input matInput [(ngModel)]="data.heroButtonText" [formControl]="controls.heroButtonTextControl">
      <mat-error *ngIf="controls.heroButtonTextControl.invalid">{{ getErrorMessage(controls.heroButtonTextControl) }}</mat-error>
    </mat-form-field>
  </div>

  <div class="field">
    <mat-form-field>
      <mat-label>Trailer Title</mat-label>
      <input matInput [(ngModel)]="data.trailerTitle" [formControl]="controls.trailerTitleControl">
      <mat-error *ngIf="controls.trailerTitleControl.invalid">{{ getErrorMessage(controls.trailerTitleControl) }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Webinar Trailer Advantage 1</mat-label>
      <input matInput [(ngModel)]="data.trailerAdvantages[0]" [formControl]="controls.trAdv1Control">
      <mat-error *ngIf="controls.trAdv1Control.invalid">{{ getErrorMessage(controls.trAdv1Control) }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Webinar Trailer Advantage 2</mat-label>
      <input matInput [(ngModel)]="data.trailerAdvantages[1]" [formControl]="controls.trAdv2Control">
      <mat-error *ngIf="controls.trAdv2Control.invalid">{{ getErrorMessage(controls.trAdv2Control) }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Webinar Trailer Advantage 3</mat-label>
      <input matInput [(ngModel)]="data.trailerAdvantages[2]" [formControl]="controls.trAdv3Control">
      <mat-error *ngIf="controls.trAdv3Control.invalid">{{ getErrorMessage(controls.trAdv3Control) }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Webinar Trailer URL</mat-label>
      <input matInput [(ngModel)]="data.trailerURL" [formControl]="controls.trURLControl">
      <mat-error *ngIf="controls.trURLControl.invalid">{{ getErrorMessage(controls.trURLControl) }}</mat-error>
    </mat-form-field>
  </div>

  <div class="field">
    <mat-form-field>
      <mat-label>Current Webinar Title</mat-label>
      <input matInput [(ngModel)]="data.currentWebinarTitle" [formControl]="controls.currentWebinarTitleControl">
      <mat-error *ngIf="controls.currentWebinarTitleControl.invalid">{{ getErrorMessage(controls.currentWebinarTitleControl) }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Current Webinar Sub Title</mat-label>
      <input matInput [(ngModel)]="data.currentWebinarSubTitle" [formControl]="controls.currentWebinarSubTitleControl">
      <mat-hint>Nutze %TOPIC% um das aktuelle Thema in den Text einzufügen.</mat-hint>
      <mat-error *ngIf="controls.currentWebinarSubTitleControl.invalid">{{ getErrorMessage(controls.currentWebinarSubTitleControl) }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Webinar Login Button Text</mat-label>
      <input matInput [(ngModel)]="data.currentWebinarLoginButtonText" [formControl]="controls.currentWebinarButtonTextControl">
      <mat-error *ngIf="controls.currentWebinarButtonTextControl.invalid">{{ getErrorMessage(controls.currentWebinarButtonTextControl) }}</mat-error>
    </mat-form-field>
  </div>

  <div class="field">
    <mat-form-field>
      <mat-label>Nächstes Thema 3</mat-label>
      <input matInput [(ngModel)]="data.webinarTopics[0]" [formControl]="controls.tpc1Control">
      <mat-error *ngIf="controls.tpc1Control.invalid">{{ getErrorMessage(controls.tpc1Control) }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Nächstes Thema 2</mat-label>
      <input matInput [(ngModel)]="data.webinarTopics[1]" [formControl]="controls.tpc2Control">
      <mat-error *ngIf="controls.tpc2Control.invalid">{{ getErrorMessage(controls.tpc2Control) }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Nächstes Thema 1</mat-label>
      <input matInput [(ngModel)]="data.webinarTopics[2]" [formControl]="controls.tpc3Control">
      <mat-error *ngIf="controls.tpc3Control.invalid">{{ getErrorMessage(controls.tpc3Control) }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Aktuelles Webinar Thema</mat-label>
      <input matInput [(ngModel)]="data.webinarTopics[3]" [formControl]="controls.tpc4Control">
      <mat-error *ngIf="controls.tpc4Control.invalid">{{ getErrorMessage(controls.tpc4Control) }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Vergangenes Thema 1</mat-label>
      <input matInput [(ngModel)]="data.webinarTopics[4]" [formControl]="controls.tpc5Control">
      <mat-error *ngIf="controls.tpc5Control.invalid">{{ getErrorMessage(controls.tpc5Control) }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Vergangenes Thema 2</mat-label>
      <input matInput [(ngModel)]="data.webinarTopics[5]" [formControl]="controls.tpc6Control">
      <mat-error *ngIf="controls.tpc6Control.invalid">{{ getErrorMessage(controls.tpc6Control) }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Vergangenes Thema 3</mat-label>
      <input matInput [(ngModel)]="data.webinarTopics[6]" [formControl]="controls.tpc7Control">
      <mat-error *ngIf="controls.tpc7Control.invalid">{{ getErrorMessage(controls.tpc7Control) }}</mat-error>
    </mat-form-field>
  </div>

</div>

<div mat-dialog-actions>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="data"
    cdkFocusInitial
    [disabled]="!isFormValid()"
  >
    Content aktualisieren
  </button>
</div>
