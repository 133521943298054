import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebinarGroupService {

  constructor(private httpService: HttpService) {
  }

  async createGroup(groupName: string, image: string): Promise<Observable<Object | void>> {
    return this.httpService.post('/webinar-group/create', {
      name: groupName,
      image
    });
  }

  async deleteGroup(groupId: string): Promise<Observable<Object | void>> {
    return this.httpService.post('/webinar-group/delete', {
      _id: groupId,
    });
  }

  async getGroups() {
    return this.httpService.get('/webinar-group');
  }

  async getStats(_id: string) {
    return this.httpService.post('/webinar-group/stats', {_id});
  }

  async generatePublicCode(_id: string, name: string, mail: string) {
    return this.httpService.post('/generate/code/public', {
      _id,
      name,
      mail,
      amount: 1,
    });
  }

  async generateCompanyCode(_id: string, receiverMail: string, amount: number) {
    return this.httpService.post('/generate/code/business', {
      _id,
      receiverMail,
      amount,
    });
  }

}
