import {Component, Inject} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface CodePublicData {
  fullName: string;
  mail: string;
}

@Component({
  selector: 'admin-dialog-webinar-code-public',
  templateUrl: './webinar-code-public-dialog.component.html',
  styleUrls: ['./webinar-code-public-dialog.component.scss'],
})
export class DialogWebinarCodePublic {

  /**
   * Form Controls
   */
  controls = {
    fullNameControl: new FormControl('', [Validators.required]),
    mailControl: new FormControl('', [Validators.required, Validators.email]),
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CodePublicData,
  ) {}


  /**
   * get error messages by the form control
   */
  getErrorMessage(control: FormControl): string | void {
    if (control.hasError('required')) {
      return 'Bitte fülle das Feld aus';
    }

    if (control.hasError('email')) {
      return 'Bitte gebe eine E-Mail Adresse ein';
    }
  }

  /**
   * used to disable the button until the form is valid
   */
  isFormValid(): boolean {
    let valid = true;

    if (this.controls.fullNameControl.hasError('required')) {
      valid = false;
    }

    if (this.controls.mailControl.hasError('email')) {
      valid = false;
    }

    if (this.controls.mailControl.hasError('required')) {
      valid = false;
    }

    return valid;
  }

}
