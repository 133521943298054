<div class="switcher">

  <!--
    Switcher is used to switch between
    the consumer and business list
  -->
  <div class="switcher__slide">
    <label class="switcher__label">
      <span class="switcher__text"> Konsumenten </span>

      <mat-slide-toggle
        class="switcher__toggle"
        [(ngModel)]="checkoutSwitcher"
      >
      </mat-slide-toggle>

      <span class="switcher__text"> Unternehmen </span>
    </label>
  </div>





  <!--
    Customers List
  -->
  <div class="switcher__list" *ngIf="!checkoutSwitcher">
    <payment-checkout-card
      stripeIndex=1

      [title]="{ headline: '1 Online Seminar Ticket', subheadline: 'buchen' }"
      price="4,90"
      label="Jetzt Ticket sichern"
    >
    </payment-checkout-card>

    <payment-checkout-card
      stripeIndex=2

      banner="Unser Topseller!"
      [title]="{ headline: '5 Online Seminar Tickets', subheadline: 'buchen' }"
      price="19,90"
      label="Jetzt Ticket sichern"
    >
    </payment-checkout-card>

    <payment-checkout-card
      stripeIndex=3

      [title]="{ headline: '10 Online Seminar Tickets', subheadline: 'buchen' }"
      price="29,90"
      label="Jetzt Ticket sichern"
    >
    </payment-checkout-card>
  </div>



  <!--
    Business List
  -->
  <div class="switcher__list" *ngIf="checkoutSwitcher">
    <payment-checkout-card
      stripeIndex=4

      [title]="{ headline: 'Online Seminar', subheadline: '< 25 Teilnehmer buchen' }"
      price="99,00"
      label="Jetzt Buchen"
    >
    </payment-checkout-card>

    <payment-checkout-card
      stripeIndex=5

      banner="Unser Topseller!"
      [title]="{ headline: 'Online Seminar', subheadline: '< 50 Teilnehmer buchen' }"
      price="149,00"
      label="Jetzt Buchen"
    >
    </payment-checkout-card>

    <payment-checkout-card
      stripeIndex=6

      [title]="{ headline: 'Online Seminar', subheadline: '< 100 Teilnehmer buchen' }"
      price="249,00"
      label="Jetzt Buchen"
    >
    </payment-checkout-card>
  </div>


</div>
