<h1 mat-dialog-title>
  Webinar Link teilen
</h1>

<div mat-dialog-content>
  <mat-form-field>
    <mat-label>URL</mat-label>
    <input matInput [(ngModel)]="data.webinarURL" [formControl]="controls.urlControl" (blur)="parseURL()">
    <mat-error *ngIf="controls.urlControl.invalid">{{ getErrorMessage(controls.urlControl) }}</mat-error>
  </mat-form-field>
  <a [href]="previewURL" target="_blank">Live Preview</a>
</div>

<div mat-dialog-actions>
  <button 
    mat-flat-button 
    color="primary" 
    
    [disabled]="data.amount <= 0 || this.controls.urlControl.hasError('required')" 
    [mat-dialog-close]="data.webinarURL"
    cdkFocusInitial>Webiar Link mit {{data.amount}} Teilnehmern teilen
  </button>
</div>
