<mat-expansion-panel class="panel">
  <mat-expansion-panel-header class="panel__header">
    <span class="panel__header__topic panel__header--bold"> {{ webinar.topic }} </span>
    <span class="panel__header__date"> &nbsp;({{webinar.date}} - {{webinar.time}}) </span>
    <span class="panel__header__date"> &nbsp; | DL: ({{webinar.date_registerDeadline}} - {{webinar.time_registerDeadline}}) </span>

    <span
      class="panel__header__label"
      [class.panel__header__label--soon]="service.getDaysUntil(webinar) <= 3 && service.getDaysUntil(webinar) > 0"
      [class.panel__header__label--hurry]="service.getDaysUntil(webinar) == 0"
      [class.panel__header__label--passed]="service.getDaysUntil(webinar) < 0"
    >
      ({{ service.getWebinarLabel(webinar) }})
    </span>
  </mat-expansion-panel-header>


  <!--
    Expansion Content
  -->
  <ng-template matExpansionPanelContent>
    <!--
      Group Controls (Buttons - Edit, Upload Handout, Share Link, Stats, Delete)
    -->
    <div class="panel__controls">
      <div class="panel__controls__column">

        <!--
          BUTTON EDIT
        -->
        <button
          class="panel__button"

          mat-flat-button
          color="primary"

          (click)="panelWebinarDialog.editWebinar(this.group, this.webinar)"
        >
          Bearbeiten
        </button>


        <!--
          BUTTON UPLOAD HANDOUT
        -->
        <button
          class="panel__button"

          mat-flat-button
          color="primary"
          [disabled]="service.getDaysUntil(webinar) >= 0"

          (click)="panelWebinarDialog.shareWebinarHandout(this.webinar)"
        >
          Handout hochladen
        </button>


        <!--
          BUTTON SHARE LINK_
        -->
        <button
          class="panel__button"

          mat-flat-button
          color="primary"
          [disabled]="service.getDaysUntil(webinar) < 0"

          (click)="panelWebinarDialog.shareWebinarLink(this.webinar)"
        >
          Webinar Link teilen
        </button>

        <!--
          BUTTON (STROKED) STATS
        -->
        <button
          class="panel__button"

          mat-stroked-button
          color="primary"

          (click)="panelWebinarDialog.statsWebinar(this.group, this.webinar)"
        >
          Statistik
        </button>

      </div>

      <!--
        This Column will be stick to the right
        so that the delete button is on the right side
      -->
      <div class="panel__controls__column">
        <!--
          BUTTON (STROKED & DANGER) DELETE
        -->
        <button
          class="panel__button panel__button--danger"

          mat-stroked-button
          color="primary"

          (click)="panelWebinarDialog.deleteWebinar(this.group, this.webinar)"
        >
          Webinar löschen
        </button>
      </div>
    </div>


    <!--
      Content
    -->
    <div class="panel__content">

      <!-- THEMA -->
      <div class="panel__content__item">
        <p class="panel__content__breadcrumb">
          Thema
        </p>
        <p class="panel__content__text">
          {{ webinar.topic }}
        </p>
      </div>

      <!-- WAS WIRST DU LERNEN? -->
      <div class="panel__content__item">
        <p class="panel__content__breadcrumb">
          Was wirst du lernen?
        </p>
        <p class="panel__content__text">
          1. {{ webinar.advantages[0] }} <br>
          2. {{ webinar.advantages[1] }} <br>
          3. {{ webinar.advantages[2] }}
        </p>
      </div>

      <!-- DATUM, UHRZEIT, DAUER -->
      <div class="panel__content__item panel__content__item--inline">
        <div>
          <p class="panel__content__breadcrumb">
            Datum
          </p>
          <p class="panel__content__text">
            {{ webinar.date }}
          </p>
        </div>

        <div>
          <p class="panel__content__breadcrumb">
            Uhrzeit
          </p>
          <p class="panel__content__text">
            {{ webinar.time }}
          </p>
        </div>

        <div>
          <p class="panel__content__breadcrumb">
            Dauer
          </p>
          <p class="panel__content__text">
            {{ webinar.duration }}
          </p>
        </div>
      </div>

      <!-- DATUM DL & UHRZEIT DL -->
      <div class="panel__content__item panel__content__item--inline">
        <div>
          <p class="panel__content__breadcrumb">
            Datum (Register Deadline)
          </p>
          <p class="panel__content__text">
            {{ webinar.date_registerDeadline }}
          </p>
        </div>

        <div>
          <p class="panel__content__breadcrumb">
            Uhrzeit (Register Deadline)
          </p>
          <p class="panel__content__text">
            {{ webinar.time_registerDeadline }}
          </p>
        </div>
      </div>

    </div>
  </ng-template>
</mat-expansion-panel>
