import {Injectable} from '@angular/core'
import { Router } from '@angular/router';
import { CmsService } from 'src/app/services/cms/cms.service';
import { WebinarGroupService } from 'src/app/services/webinar-group/webinar-group.service';
import {AdminDialogService} from './dialog/admin.dialog.service';
import { DialogCMSEdit } from './dialog/general/edit-cms-dialog/edit-cms-dialog.component';
import {DialogWebinarGroupCreate} from './dialog/webinar-group/create-webinar-group-dialog/create-webinar-group-dialog.component';
import { DialogUtilsWebinarSuccessDialog } from './dialog/webinar-group/utils-share-success-webinar-dialog/webinar-share-success-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class AdminDialog {

  constructor(
    private readonly dialogService: AdminDialogService,
    private readonly webinarGroupService: WebinarGroupService,
    private readonly router: Router,
    private readonly cmsSerivce: CmsService
  ) {}



  /**
   * Create a webinar group
   */
  createWebinarGroup(): void {
    this.dialogService.openDialog(DialogWebinarGroupCreate, data => {
      /**
       * stopping the callback when the retuned data is undefined
       * because when the data is undefined then the dialog was closed
       * by pressing ESC
       */
      if (data === undefined) return;

      /**
       * Create a Webinar Group
       */
      this.webinarGroupService.createGroup(data.groupName, data.image).then(result => {
        /**
         * subscribing to the result
         */
        result.subscribe(() => {
          /**
           * after the webinar group is created, we are going to reload the page
           */
          /**
           * required to allow reloading on the same route
           */
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          /**
           * navigating to the same route to reload the page
           */
          this.router.navigate(['admin']);
        })
      });
    }, {
      groupName: '',
      image: ''
    });
  }

  /**
   * Edit the cms content
   */
  editCMSContent(): void {
    /**
     * fetching the cms data to pre-fill it in the dialog
     */
    this.cmsSerivce.getData().then(result => {
      /**
       * subscribe to the result
       */
      result.subscribe(data => {
        /**
         * setting data to first item of array
         */
        data = data[0];

        /**
         * open the dialog
         */
        this.dialogService.openDialog(DialogCMSEdit, data => {
          /**
           * stopping the callback when the retuned data is undefined
           * because when the data is undefined then the dialog was closed
           * by pressing ESC
           */
          if (data === undefined) return;

          /**
           * update the cms data
           */
          this.cmsSerivce.setData(data).then(result => {
            /**
             * subscribing to the result
             */
            result.subscribe(() => {
              /**
               * showing the success dialog, after the content is updated
               */
              this.dialogService.openDialog(DialogUtilsWebinarSuccessDialog, () => {}, {
                title: 'Erfolgreich abgespeichert!',
                content: 'Der Content ist nun aktualisiert.'
              });
            })
          });
        }, data);
      });
    });
  }

}
