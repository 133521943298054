<h1 mat-dialog-title>
  Webinar bearbeiten ({{data.Group.name}})
</h1>

<div mat-dialog-content>
  <div class="field">
    <mat-form-field>
      <mat-label>Thema</mat-label>
      <input matInput [(ngModel)]="data.Webinar.topic" [formControl]="controls.topicControl">
      <mat-error *ngIf="controls.topicControl.invalid">{{ getErrorMessage(controls.topicControl) }}</mat-error>
    </mat-form-field>
  </div>

  <div class="field">
    <mat-form-field>
      <mat-label>Was wirst du lernen? (1)</mat-label>
      <input matInput [(ngModel)]="data.Webinar.advantages[0]" [formControl]="controls.advantage1Control">
      <mat-error
        *ngIf="controls.advantage1Control.invalid">{{ getErrorMessage(controls.advantage1Control) }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Was wirst du lernen? (2)</mat-label>
      <input matInput [(ngModel)]="data.Webinar.advantages[1]" [formControl]="controls.advantage2Control">
      <mat-error
        *ngIf="controls.advantage2Control.invalid">{{ getErrorMessage(controls.advantage2Control) }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Was wirst du lernen? (3)</mat-label>
      <input matInput [(ngModel)]="data.Webinar.advantages[2]" [formControl]="controls.advantage3Control">
      <mat-error
        *ngIf="controls.advantage3Control.invalid">{{ getErrorMessage(controls.advantage3Control) }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>YouTube Vorschau URL</mat-label>
      <input matInput [(ngModel)]="data.Webinar.youtubeVideo" [formControl]="controls.youtubeControl">
      <mat-error *ngIf="controls.youtubeControl.invalid">{{ getErrorMessage(controls.youtubeControl) }}</mat-error>
    </mat-form-field>
  </div>

  <div class="field">
    <mat-form-field>
      <mat-label>Datum (TT.MM.JJJJ)</mat-label>
      <input matInput [(ngModel)]="data.Webinar.date" [formControl]="controls.dateControl">
      <mat-error *ngIf="controls.dateControl.invalid">{{ getErrorMessage(controls.dateControl) }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Uhrzeit (HH:MM)</mat-label>
      <input matInput [(ngModel)]="data.Webinar.time" [formControl]="controls.timeControl">
      <mat-error *ngIf="controls.timeControl.invalid">{{ getErrorMessage(controls.timeControl) }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Dauer</mat-label>
      <input matInput [(ngModel)]="data.Webinar.duration" [formControl]="controls.durationControl">
      <mat-error *ngIf="controls.durationControl.invalid">{{ getErrorMessage(controls.durationControl) }}</mat-error>
    </mat-form-field>
  </div>

  <div class="field">
    <mat-form-field>
      <mat-label>Register-Deadline Datum (TT.MM.JJJJ)</mat-label>
      <input matInput [(ngModel)]="data.Webinar.date_registerDeadline" [formControl]="controls.dateRegisterDeadlineControl">
      <mat-error
        *ngIf="controls.dateRegisterDeadlineControl.invalid">{{ getErrorMessage(controls.dateRegisterDeadlineControl) }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Register-Deadline Uhrzeit (HH:MM)</mat-label>
      <input matInput [(ngModel)]="data.Webinar.time_registerDeadline" [formControl]="controls.timeRegisterDeadlineControl">
      <mat-error
        *ngIf="controls.timeRegisterDeadlineControl.invalid">{{ getErrorMessage(controls.timeRegisterDeadlineControl) }}</mat-error>
    </mat-form-field>
  </div>
</div>

<div mat-dialog-actions>
  <button 
    mat-flat-button 
    color="primary"
    [mat-dialog-close]="data" 
    cdkFocusInitial
    
    [disabled]="!isFormValid()"
  >
    Webinar aktualisieren
  </button>
</div>
