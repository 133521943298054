import {Component, Input, OnInit} from '@angular/core';
import Webinar from 'src/app/interfaces/webinar.interface';
import {PanelWebinarService} from './panel-webinar.service';
import {PanelWebinarDialog} from './panel-webinar.dialog';

@Component({
  selector: 'admin-panel-webinar',
  templateUrl: './panel-webinar.component.html',
  styleUrls: ['./panel-webinar.component.scss']
})
export class PanelWebinarComponent {

  @Input() group;
  @Input() webinar: Webinar;

  constructor(
    readonly service: PanelWebinarService,
    readonly panelWebinarDialog: PanelWebinarDialog,
  ) {}

}
