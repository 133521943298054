<div class="footer">
  <div class="container mt-5 pt-5">
    <div class="col text-center">
      <a href="https://www.facebook.com/BCMSolutionsGmbH" target="_blank"><img
        src="https://bcmsolutions.de/wp-content/themes/bcm/img/fic-facebook.png"
        class="social-icon"></a>
      <a href="https://www.instagram.com/bcm.solutions/" target="_blank"><img
        src="https://bcmsolutions.de/wp-content/themes/bcm/img/fic-instagram.png"
        class="social-icon"></a>
      <a href="https://www.linkedin.com/company/bcm-solutions-gmbh" target="_blank"><img
        src="https://bcmsolutions.de/wp-content/themes/bcm/img/fic-linkedin.png"
        class="social-icon"></a>
      <a href="https://www.xing.com/companies/bcmsolutionsgmbh" target="_blank"><img
        src="https://bcmsolutions.de/wp-content/themes/bcm/img/fic-xing.png" class="social-icon"></a>
      <a href="https://twitter.com/BCMSolutionsDE" target="_blank"><img
        src="https://bcmsolutions.de/wp-content/themes/bcm/img/fic-twitter.png" class="social-icon"></a>
    </div>
    <div class="row mt-5 pt-5">
      <div class="col text-center">
        <hr>
        <a href="https://bcmsolutions.de/impressum" class="mr-2">Impressum</a> <a
        href="https://bcmsolutions.de/datenschutz" class="ml-2">Datenschutz</a>
      </div>
    </div>
  </div>
</div>
