import {Component, Inject} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface EditWebinarData {
  Group;
  Webinar;
}

@Component({
  selector: 'admin-dialog-webinar-edit',
  templateUrl: './edit-webinar-dialog.component.html',
  styleUrls: ['./edit-webinar-dialog.component.scss'],
})
export class DialogWebinarEdit {

  controls = {
    topicControl: new FormControl('', [Validators.required]),
    advantage1Control: new FormControl('', [Validators.required]),
    advantage2Control: new FormControl('', [Validators.required]),
    advantage3Control: new FormControl('', [Validators.required]),
    youtubeControl: new FormControl('', [Validators.required]),
    dateControl: new FormControl('', [Validators.required]),
    timeControl: new FormControl('', [Validators.required]),
    durationControl: new FormControl('', [Validators.required]),
    dateRegisterDeadlineControl: new FormControl('', [Validators.required]),
    timeRegisterDeadlineControl: new FormControl('', [Validators.required]),
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditWebinarData,
  ) {}

  /**
   * Get the error message
   */
  getErrorMessage(control: FormControl): string | void {
    if (control.hasError('required')) {
      return 'Bitte fülle das Feld aus';
    }
  }

  /**
   * used to disable the button until the form is valid
   */
  isFormValid(): boolean {
    let valid = true;

    /**
     * looping through every form control
     */
    for (const key in this.controls) {
      const control = this.controls[key];
      
      /**
       * setting valid to false if a form control has an error
       */
      if (control.hasError('required')) {
        valid = false;
      }
    }

    return valid;
  }

}
