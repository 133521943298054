<h1 mat-dialog-title>
  {{data.title}}
</h1>

<div mat-dialog-content>
  <p>
    {{data.content}}
  </p>
</div>

<div mat-dialog-actions>
  <button 
    class="dialog__button"
    mat-stroked-button   
    cdkFocusInitial
    
    (click)="close()" 
  >
    Schließen
  </button>
</div>
