import {Injectable} from '@angular/core';
import {AdminDialogService} from '../../dialog/admin.dialog.service';
import {WebinarService} from '../../../../services/webinar/webinar.service';
import {DialogWebinarStats} from '../../dialog/webinar/webinar-stats-dialog/webinar-stats-dialog.component';
import { DialogWebinarDelete } from '../../dialog/webinar/delete-webinar-dialog/delete-webinar-dialog.component';
import { Router } from '@angular/router';
import { DialogWebinarHandoutShare } from '../../dialog/webinar/webinar-share-handout-dialog/webinar-share-handout-dialog.component';
import { DialogUtilsWebinarSuccessDialog } from '../../dialog/webinar-group/utils-share-success-webinar-dialog/webinar-share-success-dialog.component';
import { DialogWebinarLinkShare } from '../../dialog/webinar/webinar-share-link-dialog/webinar-share-link-dialog.component';
import { DialogWebinarEdit } from '../../dialog/webinar/edit-webinar-dialog/edit-webinar-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class PanelWebinarDialog {

  constructor(
      private readonly dialogService: AdminDialogService,
      private readonly webinarService: WebinarService,
      private readonly router: Router,
  ) {}

  /**
   * Delete a Webinar
   */
  deleteWebinar(Group, Webinar): void {
    this.dialogService.openDialog(DialogWebinarDelete, state => {
      /**
       * if the state is not true then the dialog was closed
       * by pressing ESC or clicking on the Cancel button.
       * In this case we don't want to execute our callback so we 
       * are stopping the callback with return;
       */
      if (!state) return;
      
      /**
       * Sending a request to the back-end
       */
      this.webinarService.deleteWebinar(Group._id, Webinar._id).then(result => {
        /**
         * subscribing to the result
         */
        result.subscribe(() => {
          /**
           * the group was deleted so we have to
           * reload the page to update the available groups
           */

          /**
           * required to allow reloading on the same route
           */
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          /**
           * navigating to the same route to reload the page
           */
          this.router.navigate(['admin']);
        });
      });

    }, {
      Group,
      Webinar,
      // passing in a state to check if the dialog was closed by pressing cancel or ESC
      // state is undefined if it was closed by cancel or ESC - in this case we don't want to run our callback
      state: true
    });
  }

  /**
   * Stats of a Webinar
   */
  statsWebinar(Group, Webinar): void {
    /**
     * fetching the stats
     */
    this.webinarService.getStats(Webinar._id).then(result => {
    /**
     * subscribing to the result
     */
    result.subscribe(data => {
        /**
         * opening the dialog after the stats are fetched
         * passing in the data as codes 
         */
        this.dialogService.openDialog(DialogWebinarStats, () => {}, {
            Group,
            codes: data
            }, 900);
        });
    });
  }

  /**
   * Share a Handout to all webinar members
   */
  shareWebinarHandout(Webinar): void {
    /**
     * fetching the stats
     */
    this.webinarService.getStats(Webinar._id).then(result => {
      /**
       * subscribing to the result
       */
      result.subscribe(data => {
        /**
         * opening the dialog after the stats are fetched
         * passing in the data as codes 
         */
        this.dialogService.openDialog(DialogWebinarHandoutShare, data => {
          if (data == undefined) return;

          /**
           * Create Form Data that gets appended
           * to the Back-End Request. This is used to
           * store the File Data inside of it.
           */
          const formData = new FormData();
          /**
           * data = file -> gets returned from the dialog on submit
           * set the data (file) as field 'handout' on the Form Data
           */
          formData.append('handout', data);
          /**
           * also appending the webinar id
           */
          formData.append('webinarId', Webinar._id);

          /**
           * sending the request to the Back-End
           */
          this.webinarService.shareHandout(formData).then(result => {
            /**
             * subscribing to the result
             */
            result.subscribe(() => {
              /**
               * opening a success dialog
               */
              this.dialogService.openDialog(DialogUtilsWebinarSuccessDialog, () => {}, {
                title: 'Webinar Handout erfolgreich geteilt!',
                content: 'Das Webinar Handout wurde erfolgreich an alle Teilnehmer versendet.'
              });
            });
          })
        }, {
            file: undefined,
            amount: data.length
        });
      });
    });
  }

  /**
   * Share the Link to the Webinar with all members
   */
  shareWebinarLink(Webinar): void {
    /**
     * fetching the stats
     */
    this.webinarService.getStats(Webinar._id).then(result => {
      /**
       * subscribing to the result
       */
      result.subscribe(data => {
        /**
         * opening the dialog after the stats are fetched
         * passing in the data as codes 
         */
        this.dialogService.openDialog(DialogWebinarLinkShare, link => {
          if (link == undefined) return;
          
          /**
           * sending the request to the Back-End
           */
          this.webinarService.shareLink(Webinar._id, link).then(result => {
            /**
             * subscribing to the result
             */
            result.subscribe(() => {
              /**
               * opening a success dialog
               */
              this.dialogService.openDialog(DialogUtilsWebinarSuccessDialog, () => {}, {
                title: 'Webinar Link erfolgreich geteilt!',
                content: 'Der Webinar Link wurde erfolgreich an alle Teilnehmer versendet.'
              });
            });
          })
        }, {
            webinarURL: '',
            amount: data.length
        });
      });
    });
  }

  /**
   * Edit a existing webinar
   */
  editWebinar(Group, Webinar): void {
    this.dialogService.openDialog(DialogWebinarEdit, data => {
      /**
       * stopping the callback when the retuned data is undefined
       * because when the data is undefined then the dialog was closed
       * by pressing ESC
       */
      if (data === undefined) return;
      /**
       * creating a webinar
       */      
      this.webinarService.editWebinar(
        Group._id,
        Webinar._id,
        data.Webinar.topic,
        [
          data.Webinar.advantages[0],
          data.Webinar.advantages[1],
          data.Webinar.advantages[2],
        ],
        data.Webinar.youtubeVideo,
        data.Webinar.date,
        data.Webinar.time,
        data.Webinar.duration,
        data.Webinar.date_registerDeadline,
        data.Webinar.time_registerDeadline,
      ).then(result => {
        /**
         * subscribing to the result
         */
        result.subscribe(() => {
          /**
           * after the webinar is created, we are going to reload the page
           */
          /**
           * required to allow reloading on the same route
           */
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          /**
           * navigating to the same route to reload the page
           */
          this.router.navigate(['admin']);
        });
      });
    }, {
      Group,
      Webinar
    })
  }
}