<h1 mat-dialog-title>
  Das hat leider nicht geklappt!
</h1>

<div mat-dialog-content>
  <p>
    Bei der übermittlung der Daten ist leider ein Fehler unterlaufen. Bitte probiere es erneut!
  </p>
</div>

<div mat-dialog-actions>
  <button mat-flat-button color="primary" [mat-dialog-close] cdkFocusInitial>Okay, verstanden</button>
</div>
