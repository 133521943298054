import {Component, Inject} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface ShareWebinarURLData {
  webinarURL: string;
  amount: number;
}

@Component({
  selector: 'admin-dialog-webinar-link-share',
  templateUrl: './webinar-share-link-dialog.component.html',
  styleUrls: ['./webinar-share-link-dialog.component.scss'],
})
export class DialogWebinarLinkShare {

  controls = {
    urlControl: new FormControl('', [Validators.required]),
  };
  previewURL: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ShareWebinarURLData,
  ) {}
  

  /**
   * Get the error message for a form control
   */
  getErrorMessage(control: FormControl): string | void {
    if (control.hasError('required')) {
      return 'Bitte fülle das Feld aus';
    }
  }

  /**
   * parse the preview urls without a http or https protocol to a https protocol
   */
  parseURL() {
    if (!this.data.webinarURL.startsWith('http://') && !this.data.webinarURL.startsWith('https://')) {
      this.previewURL = `https://${this.data.webinarURL}`;
    }
  }
}
