import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GoogleAuthService {

  storeJWT(token: string) {
    localStorage.setItem('-bcm-jwt', token);
  }

  getJWT() {
    return localStorage.getItem('-bcm-jwt');
  }

  deleteJWT() {
    localStorage.removeItem('-bcm-jwt');
  }

}
