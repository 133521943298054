import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {Observable} from 'rxjs';

export type CMSBody = {
  heroTitle: string;
  heroText: string;
  heroButtonText: string;

  trailerTitle: string;
  trailerAdvantages: Array<string>;
  trailerURL: string;

  currentWebinarTitle: string;
  currentWebinarSubTitle: string;
  currentWebinarLoginButtonText: string;
  webinarTopics: Array<string>
}

@Injectable({
  providedIn: 'root',
})
export class CmsService {

  constructor(private httpService: HttpService) {}

  async getData(): Promise<any> {
    return this.httpService.get('/cms');
  }

  async setData(body: CMSBody): Promise<Observable<Object>> {
    return this.httpService.post('/cms', body);
  }
}
