import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutTableComponent } from './components/checkout-table/checkout-table.component';
import { CheckoutCardComponent } from './components/checkout-table/checkout-card/checkout-card.component';
import { CompanyNameDialogeComponent } from './dialoge/company-name-dialoge/company-name-dialoge.component';
import { CheckoutFailedDialogeComponent } from './dialoge/checkout-failed-dialoge/checkout-failed-dialoge.component';
import { CheckoutSucceedDialogeComponent } from './dialoge/checkout-succeed-dialoge/checkout-succeed-dialoge.component';
import {MaterialModule} from '../material.module';



@NgModule({
  declarations: [
    // Components
    CheckoutTableComponent,
    CheckoutCardComponent,

    // Dialoges
    CompanyNameDialogeComponent,
    CheckoutFailedDialogeComponent,
    CheckoutSucceedDialogeComponent,
    CheckoutCardComponent
  ],
  exports: [
      CheckoutTableComponent
  ],
  imports: [
    // Defaults
    CommonModule,

    // Google Material Design
    MaterialModule
  ]
})
export class PaymentModule { }
