import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

/**
 * Data that will be injected by the Dialog
 */
export interface PaymentDialogCompanyNameData {
  companyName: string;
}

@Component({
  selector: 'app-company-name-dialoge',
  templateUrl: './company-name-dialoge.component.html',
  styleUrls: []
})
export class CompanyNameDialogeComponent {

  /**
   * Injecting the data
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PaymentDialogCompanyNameData,
  ) {}

}
