import {Injectable} from '@angular/core';
import {AdminDialogService} from '../../dialog/admin.dialog.service';
import {WebinarGroupService} from '../../../../services/webinar-group/webinar-group.service';
import {DialogWebinarGroupDelete} from '../../dialog/webinar-group/delete-webinar-group-dialog/delete-webinar-group-dialog.component';
import {DialogWebinarGroupStats} from '../../dialog/webinar-group/stats-webinar-group-dialog/webinar-group-stats-dialog.component';
import {DialogWebinarCreate} from '../../dialog/webinar-group/create-webinar-dialog/create-webinar-dialog.component';
import {Router} from '@angular/router';
import { WebinarService } from 'src/app/services/webinar/webinar.service';
import { DialogWebinarCodeCompany } from '../../dialog/webinar-group/code-company-webinar-dialog/webinar-code-company-dialog.component';
import {DialogUtilsWebinarSuccessDialog} from '../../dialog/webinar-group/utils-share-success-webinar-dialog/webinar-share-success-dialog.component';
import { DialogWebinarCodePublic } from '../../dialog/webinar-group/code-public-webinar-dialog/webinar-code-public-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class PanelWebinarGroupDialog {

  constructor(
    private readonly dialogService: AdminDialogService,
    private readonly webinarGroupService: WebinarGroupService,
    private readonly webinarService: WebinarService,
    private readonly router: Router
  ) {}


  /**
   * Delete a Webinar Group
   */
  deleteWebinarGroup(Group): void {
    this.dialogService.openDialog(DialogWebinarGroupDelete, state => {
      /**
       * if the state is not true then the dialog was closed
       * by pressing ESC or clicking on the Cancel button.
       * In this case we don't want to execute our callback so we 
       * are stopping the callback with return;
       */
      if (!state) return;
      
      /**
       * Sending a request to the back-end
       */
      this.webinarGroupService.deleteGroup(Group._id).then(result => {
        /**
         * subscribing to the result
         */
        result.subscribe(() => {
          /**
           * the group was deleted so we have to
           * reload the page to update the available groups
           */

          /**
           * required to allow reloading on the same route
           */
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          /**
           * navigating to the same route to reload the page
           */
          this.router.navigate(['admin']);
        });
      });

    }, {
      Group,
      // passing in a state to check if the dialog was closed by pressing cancel or ESC
      // state is undefined if it was closed by cancel or ESC - in this case we don't want to run our callback
      state: true
    });
  }

  /**
   * Stats of a Webinar Group
   */
  statsWebinarGroup(Group): void {
    /**
     * fetching the stats
     */
    this.webinarGroupService.getStats(Group._id).then(result => {
      /**
       * subscribing to the result
       */
      result.subscribe(data => {
        /**
         * opening the dialog after the stats are fetched
         * passing in the data as codes 
         */
        this.dialogService.openDialog(DialogWebinarGroupStats, () => {}, {
          Group,
          codes: data
        }, 900);
      });
    });
  }

  /**
   * Create a Webinar
   */
  createWebinar(Group): void {
    this.dialogService.openDialog(DialogWebinarCreate, data => {
      /**
       * stopping the callback when the retuned data is undefined
       * because when the data is undefined then the dialog was closed
       * by pressing ESC
       */
      if (data === undefined) return;
      
      /**
       * creating a webinar
       */      
      this.webinarService.createWebinar(
        Group._id,
        data.topic,
        [
          data.advantages1,
          data.advantages2,
          data.advantages3,
        ],
        data.youtubeURL,
        data.date,
        data.time,
        data.duration,
        data.date_registerDeadline,
        data.time_registerDeadline,
      ).then(result => {
        /**
         * subscribing to the result
         */
        result.subscribe(() => {
        /**
         * after the webinar is created, we are going to reload the page
         */
        /**
         * required to allow reloading on the same route
         */
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        /**
         * navigating to the same route to reload the page
         */
        this.router.navigate(['admin']);
        });
      });
    }, {
      Group,
      topic: '',
      advantages1: '',
      advantages2: '',
      advantages3: '',
      youtubeURL: '',
      date: '',
      time: '',
      duration: '',
      date_registerDeadline: '',
      time_registerDeadline: ''
    })
  }

  /**
   * Create codes for company Webinar Groups
   */
  createCodeCompanyWebinarGroup(Group): void {
    this.dialogService.openDialog(DialogWebinarCodeCompany, data => {
      /**
       * stopping the callback when the retuned data is undefined
       * because when the data is undefined then the dialog was closed
       * by pressing ESC
       */
      if (data === undefined) return;

      /**
       * generate a company code
       */
      this.webinarGroupService.generateCompanyCode(Group._id, data.mail, data.amount).then(result => {
        /**
         * subscribing to the result
         */
        result.subscribe(() => {
          /**
           * opening a success dialog
           */
          this.dialogService.openDialog(DialogUtilsWebinarSuccessDialog, () => {}, {
            title: 'Erfolgreich Codes versendet!',
            content: `Die Codes (${data.amount}) wurden erfolgreich an ${data.mail} übermittelt.`
          });
        });
      });
    }, {
      Group,
      amount: 10,
      mail: ''
    }, 400);
  }

  /**
   * Create a code for the public Webinar Group
   */
  createCodePublicWebinarGroup(Group): void {
    this.dialogService.openDialog(DialogWebinarCodePublic, data => {
      /**
       * stopping the callback when the retuned data is undefined
       * because when the data is undefined then the dialog was closed
       * by pressing ESC
       */
      if (data === undefined) return;

      /**
       * generate a company code
       */
      this.webinarGroupService.generatePublicCode(Group._id, data.fullName, data.mail).then(result => {
        /**
         * subscribing to the result
         */
        result.subscribe(() => {
          /**
           * opening a success dialog
           */
          this.dialogService.openDialog(DialogUtilsWebinarSuccessDialog, () => {}, {
            title: 'Erfolgreich Code versendet!',
            content: `Der Code wurde erfolgreich an ${data.fullName} (${data.mail}) übermittelt.`
          });
        });
      });
    }, {
      fullName: '',
      mail: ''
    }, 400);
  }
}
