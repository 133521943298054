import {Injectable} from '@angular/core';
import {HttpService} from '../../services/http/http.service';
import {Observable} from 'rxjs';
import {SettingsService} from '../../services/settings/settings.service';
import {Router} from '@angular/router';
import { WebinarGroupService } from 'src/app/services/webinar-group/webinar-group.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private readonly httpService: HttpService,
    private readonly settingsService: SettingsService,
    private readonly router: Router,
    private readonly webinarGroupService: WebinarGroupService
  ) {}

  /**
   * Will validate and handle the automatic login
   * for the Admin Area
   */
  async validateLogin(): Promise<void> {
    /**
     * Testing if the User is logged in
     */
    const result: Observable<Object> = await this.httpService.get('/google/test');
    /**
     * subscribing to the result
     */
    result.subscribe(data => {
      /**
       * checking if data.passed isn't failed
       */
      if (data['passed'] != false) {
        /**
         * getting the mail address of the logged in account
         */
        const mail = data['passed']['user']['email'];
        /**
         * checking if the mail of the account has the access rights
         * to the admin panel (also deciding if it's a moderator or administrator)
         */
        if (
          this.settingsService.settings.administrators.includes(mail)
          ||
          this.settingsService.settings.moderators.includes(mail)
        ) {
          /**
           * if he has the access rights then redirect
           * to the actual admin area, when he isn't already there
           */
          if (this.router.url != '/admin')
            this.router.navigate([ 'admin' ]);
        }
      } else {
        /**
         * if he doesn't has the access rights then redirect
         * to the login page
         */
        this.router.navigate([ 'admin/login' ]);
      }
    });
  }

  /**
   * Fetching all Webinar Groups and returning 
   * them as a Object
   */
  async getWebinarGroups() {
    return await this.webinarGroupService.getGroups()
  }

  /**
   * Sort Webinar Groups
   * ___
   * Public Group first, then sorted after the alphabet
   */
  sortWebinarGroups(Groups) {
    /**
     * Sorting Groups alphabetically
     */
    Groups.sort((a, b) => a.name.localeCompare(b.name));

    /**
     * Filtering the public group and put 
     * it into it's own variable to put it 
     * to the front in the return statement
     */

    /**
     * Storing the filtered Groups (without the public group)
     */
    const newGroups = [];
    /**
     * Storing the public Group
     */
    let publicGroup;

    /**
     * filtering groups
     */
    Groups.filter(Group => {
      	if (Group._id != this.settingsService.PUBLIC_WEBINAR_ID) {
          /**
           * pushing the group because it's not the public group
           */
          newGroups.push(Group);
        } else {
          /**
           * setting the public group
           */
          publicGroup = Group;
        }
    });

    /**
     * returning a new array with the alphabetically sorted groups
     */
    return [ publicGroup, ...newGroups ];
  }



  /**
   * Set Super Code Mode
   */
  async setSuperCodeMode(activated: boolean, groupId): Promise<Observable<any>> {
    /**
     * sending a post request to the backend 
     * to update the super code mode
     */
    return await this.httpService.post('/webinar-group/super-code/set', {
      _id: groupId,
      superCodeMode: activated
    });
  }
}
