import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { AdminService } from '../../admin.service';
import {PanelWebinarService} from '../panel-webinar/panel-webinar.service';
import {PanelWebinarGroupDialog} from './panel-webinar-group.dialog';

/**
 * Interface for the Admin Groups that
 * get's passed to every panel-webinar-group.component
 */
export interface AdminGroupObject {
  /**
   * MongoDB group id
   */
  _id: string;
  /**
   * Code Objects
   */
  codes: Array<Object>;
  /**
   * Group Name
   */
  name: string;
  /**
   * current super code mode
   */
  superCodeMode: boolean;
  /**
   * Webinar Objects
   */
  webinars: Array<Object>
}

@Component({
  selector: 'admin-panel-webinar-group',
  templateUrl: './panel-webinar-group.component.html',
  styleUrls: ['./panel-webinar-group.component.scss']
})
export class PanelWebinarGroupComponent {

  @Input() group: AdminGroupObject;

  constructor(
    readonly settingsService: SettingsService,
    private readonly service: AdminService,
    readonly panelWebinarGroupDialog: PanelWebinarGroupDialog
  ) {}

  /**
   * Updateing the super code mode state of the group
   * @param activated - the new super code mode state
   */
  async updateSuperCodeMode(activated: boolean): Promise<void> {
    const result = await this.service.setSuperCodeMode(activated, this.group._id);
    result.subscribe(() => {
      // updating the super code mode state
      this.group.superCodeMode = activated;
    });
  }

}
