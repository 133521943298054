import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'admin-dialog-webinar-group-stats',
  templateUrl: './webinar-group-stats-dialog.component.html',
  styleUrls: ['./webinar-group-stats-dialog.component.scss'],
})
export class DialogWebinarGroupStats {

  constructor(
    public dialogRef: MatDialogRef<DialogWebinarGroupStats>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  close(): void {
    this.dialogRef.close();
  }
}
