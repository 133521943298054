import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
    const button = document.querySelector('#mobilemenu');
    button.addEventListener('click', () => {
      button.classList.toggle('collapsed');

      const ul = document.querySelector('#navbarNavDropdown');
      ul.classList.toggle('show');
    });

    document.querySelectorAll('.dropdown.menu-item-has-children').forEach(el => {
      el.addEventListener('click', () => {
        el.classList.toggle('show');

        el.querySelector('ul').classList.toggle('show');
      });
    });
  }

}
