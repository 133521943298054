import { Component } from '@angular/core';
import {SettingsService} from '../../../services/settings/settings.service';
import {AdminService} from '../admin.service';

@Component({
  selector: 'admin-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  /**
   * returning the google login path,
   * generated from the backend-api url
   */
  get GoogleLoginPath() {
    return this.settingsService.settings['backendApi'] + '/google';
  }

  constructor(
    private readonly settingsService: SettingsService,
    private readonly adminService: AdminService
  ) {
    /**
     * validate the user login
     */
    this.adminService.validateLogin();
  }

}
