import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'admin-dialog-webinar-stats',
  templateUrl: './webinar-stats-dialog.component.html',
  styleUrls: ['./webinar-stats-dialog.component.scss'],
})
export class DialogWebinarStats {

  constructor(
    public dialogRef: MatDialogRef<DialogWebinarStats>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  close(): void {
    this.dialogRef.close();
  }

}
