import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import Webinar from 'src/app/interfaces/webinar.interface';

export interface DeleteWebinarData {
  Group;
  Webinar: Webinar;
  state: boolean;
}

@Component({
  selector: 'admin-dialog-webinar-delete',
  templateUrl: './delete-webinar-dialog.component.html',
  styleUrls: ['./delete-webinar-dialog.component.scss'],
})
export class DialogWebinarDelete {

  constructor(
    public dialogRef: MatDialogRef<DialogWebinarDelete>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteWebinarData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
