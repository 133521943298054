import { Component } from '@angular/core';
import {CardDisaledObject} from './checkout-card/checkout-card.component';

@Component({
  selector: 'payment-checkout-table',
  templateUrl: './checkout-table.component.html',
  styleUrls: ['./checkout-table.component.scss']
})
export class CheckoutTableComponent {

  /**
   * Used to switch between the two lists
   * false is the default state
   *
   * false = tickets for public webinar
   * true  = tickets for businesses
   */
  checkoutSwitcher: boolean = false;

  /**
   * Can be used to disable the Stripe
   * Payments and disable the cards
   * ___
   * Insert it on the card you want to disable
   * as the 'disabled' argument
   *
   * Example:
   * <payment-checkout-card ... [disabled]="disabled"></payment-checkout-card>
   */
  disabled: CardDisaledObject = {
    label: 'Aktuell Deaktiviert'
  }

}
