import {Component, Inject} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

/**
 * required data to create a webinar 
 */
export interface CreateWebinarData {
  Group;
  topic: string;
  advantages1: string;
  advantages2: string;
  advantages3: string;
  youtubeURL: string;
  date: string;
  time: string;
  duration: string;
  date_registerDeadline: string;
  time_registerDeadline: string;
}

@Component({
  selector: 'admin-dialog-webinar-group-create',
  templateUrl: './create-webinar-dialog.component.html',
  styleUrls: ['./create-webinar-dialog.component.scss'],
})
export class DialogWebinarCreate {

  /**
   * form controls for all inputs in the dialog
   */
  controls = {
    topicControl: new FormControl('', [Validators.required]),
    advantage1Control: new FormControl('', [Validators.required]),
    advantage2Control: new FormControl('', [Validators.required]),
    advantage3Control: new FormControl('', [Validators.required]),
    youtubeControl: new FormControl('', [Validators.required]),
    dateControl: new FormControl('', [Validators.required]),
    timeControl: new FormControl('', [Validators.required]),
    durationControl: new FormControl('', [Validators.required]),
    dateRegisterDeadlineControl: new FormControl('', [Validators.required]),
    timeRegisterDeadlineControl: new FormControl('', [Validators.required]),
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  

  /**
   * getting the error messages for a specific form control
   */
  getErrorMessage(control: FormControl): string | void {
    if (control.hasError('required')) {
      return 'Bitte fülle das Feld aus';
    }
  }

  /**
   * used to disable the button until the form is valid
   */
  isFormValid(): boolean {
    let valid = true;

    /**
     * looping through every form control
     */
    for (const key in this.controls) {
      const control = this.controls[key];
      
      /**
       * setting valid to false if a form control has an error
       */
      if (control.hasError('required')) {
        valid = false;
      }
    }

    return valid;
  }

}
