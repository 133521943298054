<div class="admin">

  <div class="admin__controls">
    <button
      class="admin__button"

      mat-stroked-button
      color="primary"

      (click)="adminDialog.editCMSContent()"
    >
      Content bearbeiten
    </button>


    <button
      class="admin__button"

      mat-flat-button
      color="primary"

      (click)="adminDialog.createWebinarGroup()"
    >
      Webinar Gruppe erstellen
    </button>
  </div>

  <!--
    Webinar Group Panels
   -->
  <admin-panel-webinar-group
    *ngFor="let Group of Groups"
    [group]="Group"
  >
    <!--
      Webinar Panels
    -->
    <admin-panel-webinar
      *ngFor="let Webinar of Group.webinars"
      [group]="Group"
      [webinar]="Webinar"
    >
    </admin-panel-webinar>

  </admin-panel-webinar-group>

</div>
