<h1 mat-dialog-title>
  Webinar Handout teilen
</h1>

<div mat-dialog-content>
  <input type="file" (change)="onChange($event)" accept="application/pdf"/>
</div>

<div mat-dialog-actions>
  <button 
    mat-flat-button 
    color="primary" 
    [disabled]="data.amount <= 0 || data.file === undefined" 
    [mat-dialog-close]="data.file" 
    cdkFocusInitial
  >
    Webiar Handout mit {{data.amount}} Teilnehmern teilen
  </button>
</div>
