import {Component} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  group;
  PUBLIC_WEBINAR_ID = '5f69fbc0aa88683994f17af2';

  title: string;

  setTitle(title: string): void {
    this.title = title;
  }

  setGroup(group: any): void {
    this.group = group;
  }

}

