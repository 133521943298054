import {Component, Inject} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CMSBody} from "../../../../../services/cms/cms.service";

@Component({
  selector: 'admin-dialog-cms-edit',
  templateUrl: './edit-cms-dialog.component.html',
  styleUrls: ['./edit-cms-dialog.component.scss'],
})
export class DialogCMSEdit {

  /**
   * Form Controls
   */
  controls = {
    heroTitleControl: new FormControl('', [Validators.required]),
    heroTextControl: new FormControl('', [Validators.required]),
    heroButtonTextControl: new FormControl('', [Validators.required]),

    trailerTitleControl: new FormControl('', [Validators.required]),
    trAdv1Control: new FormControl('', [Validators.required]),
    trAdv2Control: new FormControl('', [Validators.required]),
    trAdv3Control: new FormControl('', [Validators.required]),
    trURLControl: new FormControl('', [Validators.required]),

    currentWebinarTitleControl: new FormControl('', [Validators.required]),
    currentWebinarSubTitleControl: new FormControl('', [Validators.required]),
    currentWebinarButtonTextControl: new FormControl('', [Validators.required]),

    tpc1Control: new FormControl('', [Validators.required]),
    tpc2Control: new FormControl('', [Validators.required]),
    tpc3Control: new FormControl('', [Validators.required]),
    tpc4Control: new FormControl('', [Validators.required]),
    tpc5Control: new FormControl('', [Validators.required]),
    tpc6Control: new FormControl('', [Validators.required]),
    tpc7Control: new FormControl('', [Validators.required]),
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CMSBody,
  ) {}


  /**
   * Get error message for a specific form control
   */
  getErrorMessage(control: FormControl): string | void {
    if (control.hasError('required')) {
      return 'Bitte fülle das Feld aus';
    }
  }


  /**
   * used to disable the button until the form is valid
   */
  isFormValid(): boolean {
    let valid = true;

    /**
     * looping through every form control
     */
    for (const key in this.controls) {
      const control = this.controls[key];

      /**
       * setting valid to false if a form control has an error
       */
      if (control.hasError('required')) {
        valid = false;
      }
    }

    return valid;
  }

}
