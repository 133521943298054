<div class="login">

  <!--
    Google Login Button
    ___
    redirect to the back-end route where
    the google login in proceed
  -->
  <a
    class="login__button"
    [href]="GoogleLoginPath"
  >
    <!--
      Google Logo as SVG
    -->
    <img
      class="login__button__icon"
      src="https://cdn.worldvectorlogo.com/logos/google-icon.svg"
    >
    Login with Google
  </a>

</div>
