import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SettingsService} from "../settings/settings.service";

@Injectable({
  providedIn: 'root',
})
export class HttpService {

  url: string;

  constructor(private http: HttpClient, private settings: SettingsService) {
    this.url = settings.settings.backendApi;
  }


  async get(route: string, headers?: any): Promise<Observable<Object>> {
    if (headers != undefined) {
      return this.http.get(`${this.url}${route}`, {headers});
    } else {
      return this.http.get(`${this.url}${route}`);
    }
  }

  async post(route: string, body: any, headers?: any): Promise<Observable<Object>> {
    if (headers != undefined) {
      return this.http.post(`${this.url}${route}`, body, {headers});
    } else {
      return this.http.post(`${this.url}${route}`, body);
    }
  }

}
