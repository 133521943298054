<div class="header fixed-top">
  <nav class="navbar fixed-top navbar-expand-lg navbar-light navbar-white navbar-fixed-top">
    <div class="container container-xxl">
      <div class="topnav">
        <ul id="menu-top-menue" class="navbar-nav ml-auto d-none d-lg-block">
          <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-2253"
            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children dropdown menu-item-2253 nav-item">
            <a title="" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
              class="dropdown-toggle nav-link" id="menu-item-dropdown-2253">Über BCM Solutions</a>
            <ul class="dropdown-menu shadow animate slideIn" aria-labelledby="menu-item-dropdown-2253" role="menu">
              <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-2259"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2259 nav-item"><a title=""
                  href="https://bcmsolutions.de/ueber-uns/" class="dropdown-item">Über uns</a></li>
              <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-2254"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2254 nav-item"><a title=""
                  href="https://bcmsolutions.de/ueber-uns/das-team/" class="dropdown-item">Das Team</a></li>
              <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-2255"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2255 nav-item"><a title=""
                  href="https://bcmsolutions.de/ueber-uns/unsere-philosophie/" class="dropdown-item">Unsere Philosophie</a>
              </li>
              <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-2256"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2256 nav-item"><a title=""
                  href="https://bcmsolutions.de/ueber-uns/das-bcm-unternehmen/" class="dropdown-item">Das BCM-Unternehmen</a>
              </li>
              <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-2257"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2257 nav-item"><a title=""
                  href="https://bcmsolutions.de/ueber-uns/nachhaltigkeit/" class="dropdown-item">Nachhaltigkeit</a></li>
              <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-2258"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2258 nav-item"><a title=""
                  href="https://bcmsolutions.de/ueber-uns/die-personalentwicklung/" class="dropdown-item">Die
                  Personalentwicklung</a></li>
              <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-2260"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2260 nav-item"><a title=""
                  href="https://webinar.bcmsolutions.de" class="dropdown-item">Online-Seminar</a></li>
            </ul>
          </li>
        </ul>

        <!-- Calendly Link-Widget Beginn -->
        <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet">
        <script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript" async=""></script>
        <a href="" class="bcm-green ml-4 d-none d-lg-inline-block"
          onclick="Calendly.initPopupWidget({url: 'https://calendly.com/bcmsolutions/30min?hide_event_type_details=0&amp;primary_color=00b7a6'});return false;"
          title="">Kostenlose Beratung</a>
        <!-- Calendly Link-Widget Ende -->

      </div>
      <a class="navbar-brand logo" href="https://bcmsolutions.de/"><img src="https://bcmsolutions.de/wp-content/themes/bcm/img/logo-bcm.png" alt="BCM Solutions"></a>
      <button class="navbar-toggler collapsed" id="mobilemenu" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="icon-bar top-bar"></span>
        <span class="icon-bar middle-bar"></span>
        <span class="icon-bar bottom-bar"></span>
      </button>
      <div class="navbar-collapse collapse" id="navbarNavDropdown" style="">
        <ul id="menu-hauptmenue" class="navbar-nav ml-auto">
          <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-178" class="menu-item menu-item-type-post_type menu-item-object-page page_item page-item-63 menu-item-178 nav-item"><a title="" href="https://bcmsolutions.de/solutions-services/" class="nav-link" aria-current="page">Solutions &amp; Services</a></li>
          <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-183" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-183 nav-item"><a title="Projektreferenzen" href="https://bcmsolutions.de/projektreferenzen/" class="nav-link">Projektreferenzen</a></li>
          <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-503" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-503 nav-item"><a title="Blog" href="https://bcmsolutions.de/blog/" class="nav-link">Blog</a></li>
          <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-573" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children dropdown menu-item-573 nav-item"><a title="Karriere" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle nav-link" id="menu-item-dropdown-573">Karriere</a>
            <ul class="dropdown-menu shadow animate slideIn" aria-labelledby="menu-item-dropdown-573" role="menu">
              <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-575"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-575 nav-item"><a
                title="Was wir bieten" href="https://bcmsolutions.de/karriere-uebersicht/" class="dropdown-item">Was wir
                bieten</a></li>
              <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-574"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-574 nav-item"><a
                title="Stellenangebote" href="https://bcmsolutions.de/karriere-uebersicht/stellenangebote/"
                class="dropdown-item">Stellenangebote</a></li>
              <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-693"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-693 nav-item"><a
                title="Unsere Erfolgsgeschichten"
                href="https://bcmsolutions.de/karriere-uebersicht/unsere-erfolgsgeschichten/" class="dropdown-item">Unsere
                Erfolgsgeschichten</a></li>
            </ul>
          </li>
          <li itemscope="itemscope" itemtype="https://www.schema.org/SiteNavigationElement" id="menu-item-185"
            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-185 nav-item"><a title="Kontakt"
            href="https://bcmsolutions.de/kontakt/" class="nav-link">Kontakt</a></li>
        </ul>
      </div>
    </div>
  </nav>
</div>
