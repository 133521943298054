<mat-expansion-panel class="panel">
  <mat-expansion-panel-header class="panel__header">
    <p class="panel__header__name"> {{ group.name }} </p>
    <p class="panel__header__labels">
      &nbsp;
      <span
        class="panel__header__label panel__header__label--public"
        *ngIf="group._id == settingsService.PUBLIC_WEBINAR_ID"
      >
        (public)
      </span>

      <!--
        Super Code Mode Label
      -->
      <span
        class="panel__header__label panel__header__label--supercodemode"
        *ngIf="group.superCodeMode"
      >
      (super-code-mode)
    </span>
    </p>
  </mat-expansion-panel-header>

  <!--
    Content
  -->
  <ng-template
    matExpansionPanelContent
  >
    <!--
      Group Controls (Buttons - Create, Send Code, Stats, (Super-Code-Mode) Delete)
    -->
    <div class="panel__controls">
      <div class="panel__controls__column">

        <!--
          BUTTON CREATE
        -->
        <button
          class="panel__button"

          mat-flat-button
          color="primary"

          (click)="panelWebinarGroupDialog.createWebinar(group)"
        >
          Webinar erstellen
        </button>


        <!--
          BUTTON SEND CODE
        -->
        <button
          class="panel__button"

          mat-flat-button
          color="primary"

          (click)="
          group._id == settingsService.PUBLIC_WEBINAR_ID ? 
          panelWebinarGroupDialog.createCodePublicWebinarGroup(group) 
          : 
          panelWebinarGroupDialog.createCodeCompanyWebinarGroup(group)
          "
        >
          Code versenden
        </button>

        <!--
          BUTTON (STROKED) STATS
        -->
        <button
          class="panel__button"

          mat-stroked-button
          color="primary"

          (click)="panelWebinarGroupDialog.statsWebinarGroup(group)"
        >
          Statistik
        </button>



        <!--
          BUTTON (STROKED | FILLED) SUPER CODE MODE
          ___
          Only visible if the Webinar Group isn't the public webinar group
        -->
          <!--
            STROKED BUTTON
            ___
            SUPER CODE MODE === OFF
          -->
          <button
            class="panel__button panel__button--supercodemode"
            *ngIf="group.superCodeMode != true && group._id != settingsService.PUBLIC_WEBINAR_ID"

            mat-stroked-button
            color="primary"

            (click)="updateSuperCodeMode(true)"
          >
            Super Code Mode: Off
          </button>

          <!--
            FILLED BUTTON
            ___
            SUPER CODE MODE === OFF
          -->
          <button
            class="panel__button panel__button--supercodemode"
            *ngIf="group.superCodeMode && group._id != settingsService.PUBLIC_WEBINAR_ID"

            mat-flat-button
            color="primary"

            (click)="updateSuperCodeMode(false)"
          >
            Super Code Mode: On
          </button>

      </div>

      <!--
        This Column will be stick to the right
        so that the delete button is on the right side
      -->
      <div class="panel__controls__column">
        <!--
          BUTTON (STROKED & DANGER) DELETE
        -->
        <button
          class="panel__button panel__button--danger"

          mat-stroked-button
          color="primary"

          [disabled]="group._id == settingsService.PUBLIC_WEBINAR_ID"

          (click)="panelWebinarGroupDialog.deleteWebinarGroup(group)"
        >
          Gruppe löschen
        </button>
      </div>
    </div>



    <!--
      Using <ng-content> to display the child elements of
      this component. This is used to display the panel-webinars
      inside of it.
    -->
    <ng-content></ng-content>
  </ng-template>
</mat-expansion-panel>
