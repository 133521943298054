<h1 mat-dialog-title>
  Bist du dir sicher?
</h1>

<div mat-dialog-content>
  Die Webinar Gruppe ({{data.Group.name}}) und alle beinhalteten Webinare, sowie Codes werden ungültig und entfernt.
</div>

<!--
  ACTION BUTTONS
-->
<div 
  class="buttons" 
  mat-dialog-actions
>
  <!-- 
    CANCEL 
  -->
  <button 
    mat-stroked-button 
    (click)="onNoClick()"
  >
    Abbrechen
  </button>

  <!-- 
    SUBMIT 
    ___
    Passing data.state so wew know that the Dialog was closed by clicking on
    the Delete Button. If the dialog is closed by pressing ESC or clicking no, no data.state will be 
    passed and data.state will be undefined.
  -->
  <button 
    mat-flat-button 
    color="primary" 
    
    [mat-dialog-close]="data.state" 
    cdkFocusInitial
  >
    Ja, Webinar Gruppe löschen
  </button>
</div>
