<h1 mat-dialog-title>
  Super!
</h1>

<div mat-dialog-content>
  <p>
    Vielen Dank für dein Interesse an unseren Online-Seminaren. Bitte überprüfe dein E-Mail Postfach um die nächsten Schritte zu erfahren. Wir freuen uns auf dich!
  </p>
</div>

<div mat-dialog-actions>
  <button mat-flat-button color="primary" [mat-dialog-close] cdkFocusInitial>Okay, verstanden</button>
</div>
