<app-header></app-header>

<div class="wrapper">
  <div class="page-title">
    <p>{{ title }}</p>
  </div>
  <main>
    <router-outlet></router-outlet>
  </main>
</div>

<app-footer></app-footer>
