import {Injectable, TemplateRef} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ComponentType} from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root'
})
export class AdminDialogService {

  constructor(
    private readonly dialog: MatDialog
  ) {}

  /**
   * Core logic of opening up a dialog
   * @param Dialog
   * @param afterClose
   * @param data
   * @param width
   */
  openDialog(
    Dialog: ComponentType<any> | TemplateRef<any>,
    afterClose: Function = function() {},
    data: Object={},
    width: number=500
  ): void {
    /**
     * creating a reference to the Material Dialog
     */
    const ref: MatDialogRef<any> = this.dialog.open(Dialog, {
      width: `${width}px`,
      data
    });
    /**
     * running the afterClose callback
     */
    ref.afterClosed().subscribe(received => afterClose(received));
  }

}
