import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CompanyNameDialogeComponent} from '../dialoge/company-name-dialoge/company-name-dialoge.component';
import {HttpService} from '../../../services/http/http.service';
import {Observable} from 'rxjs';
import {SettingsService} from '../../../services/settings/settings.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {

  /**
   * Getting the Stripe Object from the Settings Service
   * ___
   * Needed to create a checkout session
   */
  private stripe = this.settingsService.stripe;

  constructor(
    private readonly dialog: MatDialog,
    private readonly httpService: HttpService,
    private readonly settingsService: SettingsService
  ) {}

  /**
   * This will open the Company Name Dialog that is needed
   * to get the name on cards with a higher index than 3
   *
   * @return {String} will return the company name
   */
  openPaymentCompanyNameDialog(): Promise<string> {
    /**
     * Returning a Promise and resolving
     * it after the company name was entered
     */
    return new Promise<string>(resolve => {
      /**
       * Creating the DialogRef and opening the Dialog
       */
      const DialogRef = this.dialog.open(CompanyNameDialogeComponent, {
        width: '300px',
        data: {
          companyName: ''
        }
      });

      /**
       * Subscribing for the Dialog close
       */
      DialogRef.afterClosed().subscribe((companyName: string) => {
        /**
         * Stop the Block if companyName is undefined
         */
        if (companyName === undefined) return;

        /**
         * When the companyName that was entered isn't undefined
         * resolve the promise and return the companyName (trimmed)
         * ___
         * trimmed - removed spaces at the start and at the end
         */
        companyName.trim().length > 0 && resolve(companyName.trim());
      });
    });
  }



  /**
   * Create a Checkout Session and redirect to the Checkout Page
   * @param index - the stripe index, to identify the clicked card and the selected item
   * @param [companyName] - used to create a webinar group with the company name when the index is bigger than 3
   */
  async createCheckout(index: number, companyName?: string): Promise<void> {
    /**
     * Generating the Body of the request depending on the index
     * Including the companyName when the index is bigger than 3
     */
    let body = index > 3 ? { index, webinarGroupName: companyName } : { index };

    /**
     * Sending a POST request to the /checkout route in the back-end
     * including the index and the companyName when the index is bigger than 3
     */
    const response: Observable<Object> = await this.httpService.post('/checkout', body);
    /**
     * Subscribing to the response
     * ___
     * Response will include the session.id. This is used
     * to create a Checkout Session with Stripe
     */
    response.subscribe(session => {
      /**
       * Using the redirectToCheckout method of the Stripe Library
       * to redirect the user to the actual checkout page, using the session.id
       * that was included in the response
       */
      this.stripe.redirectToCheckout({
        sessionId: session['id']
      });
    });
  }

}
