import {Injectable} from '@angular/core';
import Webinar from '../../../../interfaces/webinar.interface';

@Injectable({
  providedIn: 'root'
})
export class PanelWebinarService {

  /**
   * sorting the webinars
   * @param Webinars
   */
  sortWebinars(webinars: Array<Webinar>): Array<Webinar> {
    /**
     * storing all webinars that already passed
     */
    const passedWebinars: Array<Webinar> = this.getPassedWebinars(webinars);
    /**
     * storing all webinars that aren't already passed
     */
    const futureWebinars: Array<Webinar> = this.getFutureWebinars(webinars);

    /**
     * returning the new sorted Array
     */
    return [ ...futureWebinars, ...passedWebinars ];
  }

  /**
   * getting the fitting webinar label
   * @param Webinar
   */
  getWebinarLabel(webinar: Webinar): string {
    const days = this.getDaysUntil(webinar);

    if (days < 0)
      return 'fand statt'

    if (days == 0)
      return 'weniger als 24 Stunden'

    if (days > 0)
      if (days == 1)
        return 'in einem Tag'
      else
        return `in ${days} Tagen`
  }





  /**
   * Getting the Days until the Webinar date
   * @private helper function for the sorting process and labeling
   * @param webinar
   */
  getDaysUntil(webinar: Webinar): number {
    const { date, time } = webinar;
    /**
     * date splitted on point
     */
    const dateSplitted = date.toString().split('.');
    /**
     * time splitted on colon
     */
    const timeSplitted = time.toString().split(':');

    /**
     * deadline date
     * ___
     * passing in parsed Integers from the Date & Time Strings
     */
    const deadline: Date = new Date(+dateSplitted[2], +dateSplitted[1] - 1, +dateSplitted[0], +timeSplitted[0], +timeSplitted[1], 0);
    /**
     * current date
     */
    const now: Date = new Date();

    /**
     * calculating the delta value of the current time milliseconds
     */
    const delta = (deadline.getTime() - now.getTime()) / 1000;
    return Math.floor(delta / 86400);
  }








  /**
   * Bubble sorting all Webinars
   * @private
   */
  private bubbleSortWebinars(Webinars: Array<Webinar>): Array<Webinar> {
    /**
     * Storing the sorted webianrs
     */
    const newWebinars = [];

    Webinars.forEach(webinar => {
      /**
       * adding the days-until property to the webinar object
       * to bubble sort it easier
       */
      webinar['days-until'] = this.getDaysUntil(webinar);
      /**
       * pushing it into the newWebinars array
       */
      newWebinars.push(webinar);
    });

    /**
     * Bubble Sort Algorithm
     */
    for (let i = 0; i < newWebinars.length; i++) {
      for (let l = 0; l < newWebinars.length - 1; l++) {
        /**
         * comparing the days-until property
         */
        if (newWebinars[l]['days-until'] > newWebinars[l + 1]['days-until']) {
          /**
           * switching the items in the list
           */
          const temp = newWebinars[l];
          newWebinars[l] = newWebinars[l + 1];
          newWebinars[l + 1] = temp;
        }
      }
    }

    /**
     * return the sorted list
     */
    return newWebinars;
  }


  /**
   * returning all passed webinars
   * @param Webinars
   */
  private getPassedWebinars(Webinars: Array<Webinar>): Array<Webinar> {
    /**
     * Storing passed Webinars
     */
    const passedWebinars = [];
    /**
     * Looping through every webinar
     */
    Webinars.forEach(Webinar => {
      const days = this.getDaysUntil(Webinar);

      if (days < 0)
        passedWebinars.push(Webinar);
    });

    /**
     * reversing and returning the sorted Array
     */
    return this.bubbleSortWebinars(passedWebinars).reverse();
  }


  /**
   * returning all future webinars
   * @param Webinars
   */
  private getFutureWebinars(Webinars: Array<Webinar>): Array<Webinar> {
    /**
     * Storing future Webinars
     */
    const futureWebinars = [];
    /**
     * Looping through every webinar
     */
    Webinars.forEach(Webinar => {
      const days = this.getDaysUntil(Webinar);

      if (days >= 0)
        futureWebinars.push(Webinar);
    });

    /**
     * returning the sorted Array
     */
    return this.bubbleSortWebinars(futureWebinars);
  }

}
