import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {loadStripe} from "@stripe/stripe-js";

export function loadSettings(service: SettingsService) {
  return () => service.initializeApp();
}

class Settings {
  backendApi: string;
  administrators: Array<string>;
  moderators: Array<string>;
  stripe: string;
}

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public stripe;
  public settings: Settings = new Settings();
  public PUBLIC_WEBINAR_ID = '5f69fbc0aa88683994f17af2';
  promise;

  constructor(private http: HttpClient) {}

  initializeApp(): Promise<any> {
    this.promise = new Promise(resolve => {
      this.http.get('assets/settings.json').subscribe((response: Settings) => {
        this.settings = response;
        loadStripe(this.settings.stripe).then(res => {
          this.stripe = res;
          resolve();
        })
      })
    });

    return this.promise;
  }
}
