<h1 mat-dialog-title>
  Webinar Gruppe erstellen
</h1>

<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Firmen Name</mat-label>
    <input matInput [(ngModel)]="data.groupName" [formControl]="controls.groupNameControl">
    <mat-error *ngIf="controls.groupNameControl.invalid">{{ getErrorMessage(controls.groupNameControl) }}</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Firmen Logo URL (optional -> link zum logo)</mat-label>
    <input matInput [(ngModel)]="data.image" [formControl]="controls.imageControl">
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button 
    mat-flat-button 
    color="primary" 
    [mat-dialog-close]="data" 
    cdkFocusInitial
    [disabled]="!isFormValid()"
  >
    Neue Webinar Gruppe erstellen
  </button>
</div>
