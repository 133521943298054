import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebinarService {

  constructor(private httpService: HttpService) {
  }

  async createWebinar(
    webinarGroup: string,
    topic: string,
    advantages: Array<string>,
    youtubeVideo: string,
    date: string,
    time: string,
    duration: string,
    date_registerDeadline: string,
    time_registerDeadline: string,
  ): Promise<Observable<Object>> {
    return this.httpService.post('/webinar/create', {
      webinarGroup,
      topic,
      advantages,
      youtubeVideo,
      date,
      time,
      duration,
      date_registerDeadline,
      time_registerDeadline,
    });
  }

  async editWebinar(
    webinarGroup: string,
    webinar: string,
    topic: string,
    advantages: Array<string>,
    youtubeVideo: string,
    date: string,
    time: string,
    duration: string,
    date_registerDeadline: string,
    time_registerDeadline: string,
  ): Promise<Observable<Object>> {
    return this.httpService.post('/webinar/update', {
      webinarGroup,
      webinar,
      topic,
      advantages,
      youtubeVideo,
      date,
      time,
      duration,
      date_registerDeadline,
      time_registerDeadline,
    });
  }

  async deleteWebinar(groupId: string, webinarId: string): Promise<Observable<Object | void>> {
    return this.httpService.post('/webinar/delete', {
      webinarGroup: groupId,
      webinar: webinarId,
    });
  }

  async getStats(webinarId: string): Promise<Observable<Object | any>> {
    return this.httpService.post('/webinar/stats', {
      webinar: webinarId,
    });
  }

  async shareLink(webinarId: string, webinarLink: string): Promise<Observable<Object>> {
    return this.httpService.post('/panel/share/link', {
      webinarId,
      webinarLink,
    });
  }

  async shareHandout(formData: FormData): Promise<Observable<Object>> {
    return this.httpService.post('/panel/share/handout', formData);
  }

  wasWebinarAlready(date: string, time: string): boolean {
    const webinarDate = new Date(+date.split('.')[2], +date.split('.')[1] - 1, +date.split('.')[0], +time.split(':')[0], +time.split(':')[1], 0);
    const dateNow = new Date();

    return webinarDate.getTime() < dateNow.getTime();
  }

}
