import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin.component';
import { PanelWebinarComponent } from './panels/panel-webinar/panel-webinar.component';
import {PanelWebinarGroupComponent} from './panels/panel-webinar-group/panel-webinar-group.component';
import {MaterialModule} from '../material.module';
import {DialogWebinarGroupDelete} from './dialog/webinar-group/delete-webinar-group-dialog/delete-webinar-group-dialog.component'
import {DialogWebinarGroupStats} from './dialog/webinar-group/stats-webinar-group-dialog/webinar-group-stats-dialog.component'
import {DialogWebinarCreate} from './dialog/webinar-group/create-webinar-dialog/create-webinar-dialog.component'
import {DialogWebinarCodeCompany} from './dialog/webinar-group/code-company-webinar-dialog/webinar-code-company-dialog.component'
import {DialogWebinarCodePublic} from './dialog/webinar-group/code-public-webinar-dialog/webinar-code-public-dialog.component'
import {DialogUtilsWebinarSuccessDialog} from './dialog/webinar-group/utils-share-success-webinar-dialog/webinar-share-success-dialog.component'
import {DialogWebinarGroupCreate} from './dialog/webinar-group/create-webinar-group-dialog/create-webinar-group-dialog.component'
import {DialogCMSEdit} from './dialog/general/edit-cms-dialog/edit-cms-dialog.component';
import {DialogWebinarStats} from './dialog/webinar/webinar-stats-dialog/webinar-stats-dialog.component';
import {DialogWebinarDelete} from './dialog/webinar/delete-webinar-dialog/delete-webinar-dialog.component';
import {DialogWebinarHandoutShare} from './dialog/webinar/webinar-share-handout-dialog/webinar-share-handout-dialog.component';
import {DialogWebinarLinkShare} from './dialog/webinar/webinar-share-link-dialog/webinar-share-link-dialog.component';
import {DialogWebinarEdit} from './dialog/webinar/edit-webinar-dialog/edit-webinar-dialog.component';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    LoginComponent, 
    AdminComponent, 
    PanelWebinarComponent, 
    PanelWebinarGroupComponent,
    DialogWebinarGroupDelete,
    DialogWebinarGroupStats,
    DialogWebinarCreate,
    DialogWebinarCodeCompany,
    DialogWebinarCodePublic,
    DialogUtilsWebinarSuccessDialog,
    DialogWebinarGroupCreate,
    DialogCMSEdit,
    DialogWebinarStats,
    DialogWebinarDelete,
    DialogWebinarHandoutShare,
    DialogWebinarLinkShare,
    DialogWebinarEdit
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AdminRoutingModule,

    MaterialModule
  ]
})
export class AdminModule {}
