<h1 mat-dialog-title>
  Eine letzte Sache noch:
</h1>

<div mat-dialog-content>
  <p>
    Wie heißt Ihre Firma?
  </p>

  <mat-form-field>
    <mat-label>Firmen Name</mat-label>
    <input matInput [(ngModel)]="data.companyName">
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button
    mat-flat-button
    color="primary"

    [mat-dialog-close]="data.companyName"
    cdkFocusInitial
  >
    Weiter
  </button>
</div>
