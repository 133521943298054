<h1 mat-dialog-title>
  Code versenden ({{data.Group.name}})
</h1>

<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Empfänger E-Mail</mat-label>
    <input matInput [(ngModel)]="data.mail" [formControl]="controls.mailControl">
    <mat-error *ngIf="controls.mailControl.invalid">{{ getErrorMessage(controls.mailControl) }}</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Anzahl</mat-label>
    <input type="number" min="1" matInput [(ngModel)]="data.amount" [formControl]="controls.amountControl">
    <mat-error *ngIf="controls.amountControl.invalid">{{ getErrorMessage(controls.amountControl) }}</mat-error>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button 
    mat-flat-button 
    color="primary" 

    [mat-dialog-close]="data" 
    cdkFocusInitial

    [disabled]="!isFormValid()" 
  >
    Codes versenden
  </button>
</div>
