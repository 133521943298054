import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {GoogleAuthService} from './app/services/google-auth/google-auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private googleAuthService: GoogleAuthService, private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.googleAuthService.getJWT()}`,
      },
    });

    return next.handle(request).pipe(
      tap(
        () => {
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            this.googleAuthService.deleteJWT();
            this.router.navigate(['panel']);
          }
        },
      ),
    );
  }
}
