<!--
  Adding the modifier class 'card--topseller if
  a banner is provided. This usually means that
  this is the top-seller item.
-->
<div class="card" [class.card--topseller]="banner">

  <!--
    Displaying Banner only if a text is passed
    Banner is usually used to display the top seller
  -->
  <div class="card__banner" *ngIf="banner">
    <p class="card__subheadline">
      {{ banner }}
    </p>
  </div>


  <div class="card__title">
    <h5 class="card__headline">
      {{ title.headline }}
    </h5>
    <p class="card__subheadline">
      {{ title.subheadline }}
    </p>
  </div>


  <div class="card__price">
    <p class="card__subheadline">
      €
    </p>
    <h5 class="card__headline">
      {{ price }}
    </h5>
    <p class="card__subheadline">
      einmalig
    </p>
  </div>


  <!--
    Displaying the disabled.label if the disabled argument
    is provided, otherwise just displaying the default label
  -->
  <button
    class="card__button"
    [disabled]="!!disabled"

    mat-flat-button
    color="primary"

    (click)="onClick($event)"
  >
    {{ (!!disabled ? disabled.label : label) }}
  </button>

</div>
